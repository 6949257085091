<template>
  <div class="MyCreditsHistory">
    <Spacer num="1" />
    <Title :label="$locale['my_credits_history']" :class="{ xpadding: $isMobile || $isTablet }" />
    <Spacer num="1" />
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          class="MyCreditsHistoryCard"
          v-for="(transaction, index) in items"
          :key="index"
          :label="`<span third-bold>@${transaction.concept}</span>`"
          :desc="
            `<div>@${transaction.creator ? transaction.creator.user : $locale['app_name']} <div date>${date_locale(transaction.date)}</div></div>`
          "
          :icon="false"
          :rightDesc="`<strong nowrap mount>${transaction.type === 'reload' ? '+' : '-'} ${transaction.mount}</strong>`"
          :type="transaction.type"
          prevent
        />
        <LoadMore :morepage="statistics.hasMore" @more="updateFeed({ page: statistics.page + 1 })" />
        <CardMessage v-if="!$loading && !statistics.totalItems" :message="$locale['no_credits_history_message']" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "@/components/user/UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/credits/history`,
      storeId: "my_transactions",
      items: [],
      statistics: { totalItems: 0, page: 1 },
      limit: 20,
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.MyCreditsHistory {
  .MyCreditsHistoryCard .LargeButtonDesc {
    opacity: 1;
  }

  .MyCreditsHistoryCard [date] {
    opacity: 0.5;
    margin: $mpadding/4 0 0 0;
  }

  .MyCreditsHistoryCard [warn] {
    background-color: var(--warn-color);
    color: #fff;
    margin: $mpadding/2 0 0 0;
    display: inline-block;
  }

  .MyCreditsHistoryCard[type="reload"] [mount] {
    color: var(--color-tips);
  }
}
</style>
